import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  getCounts,
  mintNFT,
  smashNFT,
  mintthreeNFT,
  claimBeam,
  checkEgg,
  claimBeamloop,//import here} from "./utils/interact.js";
  claimBeamLoop,
  checkDopplers
} from "./utils/interact.js";
import eggsgif from 'D:/NFT_development/front_end/DragonateEaster/nft-minter-tutorial-main/minter-starter-files/src/eggs.gif'


const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [eggSmash, setEggSmash] = useState("");
  const [counteramount, setCounter] = useState("");
  const [easterEggStatus, setEggStatus] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 

  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {counts} = await getCounts();
    setWallet(address)
    setStatus(status);
    setCounter(counts)
        
    addWalletListener(); 
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => { //TODO: implement
      const { status } = await mintNFT();
    setStatus(status);  
  };

  const onSmashPressed = async () => { //TODO: implement
    const { status } = await smashNFT(eggSmash);
  setStatus(status);  
  };


  const onTokenChange = async (num) => {
    setEggStatus("")
    setEggSmash(num)
    setEggStatus(num)
  };


  const onMintthreePressed = async () => { //TODO: implement
      const { status } = await mintthreeNFT();
    setStatus(status);  
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimBeam();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimBeamLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  return (
    <div className="Minter">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect to Ethereum</span>
        )}
      </button>

    
      <h1 id="title">Easter Eggs by DragoNate</h1>


        <h1>
          <div>
      <img src={eggsgif} alt="Image" height={200} width={200} border={10}/>
      </div>
</h1>


      Happy Easter 2022!
This is my first generative NFT project! I did the art, MetaMunch did the contract stuffs and actual generation.
This is a fun little project to celebrate Easter. I wanted it to be super accessible to as many people as possible, so minting is INFINITE & FREE! You still have to pay the gas fee, but we make no profit from minting. Once the minting period is over at the end of the month, you can smash the eggs! This costs 0.02 ETH which is profit for us.
   <br></br>
      <br></br>
I made the art using some art I've made in the past, some of which was minted, a lot which was not. I have also altered some of them in my glitchy kind of style for some added uniqueness. I've wanted to do a generative project for a while now & I figured Easter would be a cool time too! Instead of coloring Easter eggs in real life, you can get a generative NFT egg 😄
Please enjoy & I'd love to know what you think of the ones you get!
   <br></br>
  
<h4>Why should you mint an egg? </h4>
For fun, for a free mint, because you like the art & wanna see some neat combinations! Some of the final images will have the eggs quite hidden, barely visible or seemingly non-existent. I assure you, your egg is there! If it is quite hidden, then it is truly an Easter egg 😉 
   <br></br>
      <br></br>
<h4>Why should you smash an egg?</h4> For fun, for the heck of it, for yootilluhtee & for the generic "Happy Easter" image. Or...ca 5% chance that your egg was hiding something else inside it 😮 These secrets will still be a smashed egg & a "Happy Easter" image, but they will be something different from the generic image. I recommend you smash only the ones you don't like!
   <br></br>
      <br></br>

      <h6>Note: By using this site and interacting with the Easter Eggs smart contract you agree to the terms & conditions listed at the bottom of this page.</h6>

      <h1 id="title">Mint: FREE - April 17th to April 30th</h1>
      <h1 id="title">Smash: 0.02 ETH - May 1st to June 1st</h1>
      <p>
       
      </p>
      <br>
      </br>      <br>
      </br>


    <h2> Mint your FREE Easter Egg!</h2>
<h6>Goes live at 6pm EST!</h6>

      <button id="mintButton" >
        CLOSED
      </button>
<br></br>
{counteramount} eggs minted!
      <br>
      </br>
     <br>
  
      </br>     <br>
      </br>     <br>
      </br>

    <h2> Smash your Easter Egg</h2>

        <h3> Which egg do you want to smash: </h3>

        <input id="entry"
          type="number"
          placeholder="Egg #"
          onChange={(event) => onTokenChange(event.target.value)}
        />

        <br></br>
      <button id="mintButton" onClick={onSmashPressed}>
        SMASH (0.02 ETH)
      </button>



      <br>
      </br>        

 <br>
      </br>        
 <br>
      </br>        

<h5>Contract address: 0xb145e90E0c73d24863F6d89EEF0daf07d9F9b61a</h5>

  <br>
      </br> 
    <h5>Terms and Conditions: Your purchase of Easter Eggs NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these Easter Eggs NFTs is meant for entertainment purposes only. The creators of Easter Eggs makes no warranties regarding future value, express or implied, to the public or to buyers. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Easter Egg NFTs are not currency and do not convey ownership in Easter Eggs or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. Easter Eggs tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Easter Eggs, future business operations, future revenue, or any control or ability to bind the entity. There is no guarantee that there will be further development or support.
</h5>

      <br>
      </br>        
      <br>
      </br>        

    </div>
  );
};



export default Minter;
